<template lang="pug">
    .relatives.flex.flex-col.items-center(class="pl-20 pr-20")
        //- .w-full(class="1280:w-1160")
        //-     .flex.items-center.pr-20.pl-20
        //-         img.block.mr-5(:src="IMGS.arrow_back")
        //-         span(@click="onBackTop" class="block text-sm text-accent cursor-pointer hover:underline") Назад
        screen-wrapper
            template(v-slot:title)
                img.w-600(:src="IMGS.title")
            template(v-slot:content)
                map-pan  

</template> 

<script>
//Components
import BaseScreenWrapper from '@/components/views/home/components/BaseScreenWrapper';
import MapPan from './components/map-pan.vue';
//Config
import { FORM } from '@/configs/text';
import { BUTTONS_VALUES } from '@/configs/names';
import { isMobile } from '@/helpers/is-mobile'


export default {
    name: "ApplyComponent",
    components: {
        'screen-wrapper': BaseScreenWrapper,
        'map-pan': MapPan,
    },
    data() {
        return {}
    },
    computed: {
        IMGS() {
            return {
                title: require('./assets/title.svg'),
            }
        },
        FORM() {
            return FORM
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        isMobile() {
            return isMobile()
        },
    },
    
    methods: {
        
        onBackTop() {
            this.onBack()
        },
        onBackBottom() {
            this.onBack()
        },
        onBack() {
            if (this.isMobile) {
                this.$router.push('/')
            } else {
                window.open('/', '_self')
            }
        },       
        
        
    },
}
</script>
