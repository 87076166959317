<template lang="pug">
    .rounded-3xl(style="background-color: #F4F6FF" class="p-10 1024:p-30 h-auto 1024:h-450")
        template
            .absolute.flex.gap-10.items-center(class="right-0 1024:right-20 top-0 1024:top-20")
                //- a(href="https://sverxnovaya.mts.ru/api/v1/uploads/files/2022-10-21/1666354139610-rules_20.12.2022.doc" download)
                a(href="https://sverxnovaya.mts.ru/api/v1/uploads/files/2022-12-01/1669921690002-rules-sn.doc" download)
                    img.block(:src="IMGS.question")
                img.block.cursor-pointer(:src="IMGS.close" @click="$emit('close')" class="hidden 1024:block")
        .text-2xl.font-cormorantBold.mb-20(class="ml-20 1024:ml-0") {{city ? `г.${city}` : ''}}
        .flex.flex-wrap.h-full.pb-30
            slot
</template>

<script>
export default {
    name: "BaseParticipants",
    props: {
        city: {
            type: String,
        },
        hideControls: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        IMGS() {
            return {
                question: require('../assets/question-icon.svg'),
                close: require('../assets/close-icon.svg'),
            }
        }
    },
}
</script>