<template lang="pug">
    div(class="w-1/2 p-2 1024:p-2")
        .flex.flex-col.gap-5.relative
            transition(name='fade' mode='out-in')
                template(v-if="participant.photo")
                    template(v-if="showPhoto")
                        .absolute.h-130.w-130.rounded-full.right-70.bottom-70.bg-cover.bg-center.shadow-md(
                            :style="`z-index: 100000;background-image: url(${baseUrl}/${participant.photo.path})`"
                        )
            .flex.items-center
                .text-sm.font-bold.text-accent.underline.cursor-pointer(@mouseover="onPhoto" @mouseleave="onPhoto") {{ participant.firstname }} {{ participant.lastname }}
                template(v-if="(participant.likes || likes) && likes !== 0")
                    .flex.items-center
                        img.block.w-15.h-15.ml-10.mr-5(:src="heartImage")
                        .text-xs {{ likes }}
            transition(name='fade' mode='out-in')
                template(v-if="showPhoto")
                    .absolute.p-3.bg-white.top-25.shadow-md.z-100.rounded(class="w-full 1024:w-300")
                        template(v-if="participant.description")
                            .text-sm.font-bold Идея для бизнеса:&nbsp;
                            .text-sm(v-html="participant.description")
                        template(v-else)
                            .text-sm Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, perspiciatis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, perspiciatis!
            .text-sm(class="block 1024:hidden") г. {{participant.city}}
            //- template(v-if="!likedId")
            //-     button.rounded-full.w-100.bg-accent.p-2.text-white.text-xs(@click="onLike") голосовать
            //- template(v-else)
            //-     button.rounded-full.w-100.bg-gray-200.p-2.text-white.text-xs.cursor-default голосовать
</template>

<script>
//Store
import { ParticipantsNameSpace, ParticipantsActionTypes, ParticipantsMutationTypes, ParticipantsGetterTypes } from '@/store/participants/types';

export default {
    name: "ParticipantsBlock",
    props: {
        participant: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            showPhoto: false,
            likes: '',
            key: 'ehTUiSiwvItg'
        }
    },
    computed: {
        baseUrl() {
            return process.env.VUE_APP_BASE_URL
        },
        heartImage() {
            return require('../assets/heart.svg')
        },
        likedId() {
            return this.$store.getters[`${ParticipantsNameSpace}/${ParticipantsGetterTypes.GetLikedId}`]
        },
    },
    async mounted() {
        this.likes = null 
        this.likes = this.participant.likes
        if (localStorage.getItem('watcher'))
            await this.$store.commit(`${ParticipantsNameSpace}/${ParticipantsMutationTypes.SetLikedId}`, { id: this.participant._id })
    },
    methods: {
        formatString(str) {
            if (str.length > 45) {
                return str.substr(0, 45) + '...'
            } else {
                return str
            }
        },
        onPhoto() {
            this.showPhoto = !this.showPhoto
        },
        async onLike() {
            const {data} = await this.$store.dispatch(`${ParticipantsNameSpace}/${ParticipantsActionTypes.AddLike}`, { id: this.participant._id })
            this.likes = data.data.likes
            localStorage.setItem('watcher', this.key)
            await this.$store.commit(`${ParticipantsNameSpace}/${ParticipantsMutationTypes.SetLikedId}`, { id: this.participant._id })
        }

    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>