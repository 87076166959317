<template lang="pug">
    section.relative(class="w-full 1024:w-1024")
        
        section.relative.w-full
            section.absolute.relative(ref="panzoom-el" class="w-full h-250 1024:h-650 1024:bottom-50")
                app-map.w-full.ease-in.duration-300(@value="onCity" id="svgmap" :resetBubbles="resetBubbles" class="hidden 1024:block")
                //Mobile map
                app-map.w-full.ease-in.duration-300(@value="onCity" id="svgmap" :resetBubbles="resetBubbles" class="block 1024:hidden" height="250px")

            transition(name='fade' mode='out-in')
                participants(v-if="showModal" class="hidden 1024:block w-500 top-80 z-50 absolute" @close="onCloseModal" :city="activeCityName")
                    template(v-for="item in filteredParticipants")
                        participant-block(:participant="item" :key="item._id")

        template(v-if="showModal")
            .absolute.top-150(class="hidden 1024:block" style="left: -155px")
                img(:src="IMGS.uzor")
            .absolute.top-320(class="hidden 1024:block" style="left: -20px")
                img(:src="IMGS.circle")
        //Zoom
        div.inline-block.absolute.right-20(class="top-150 1024:top-500")
            button.block.bg-red-200.w-40.h-40.text-white.rounded-t-md.mb-1(class="hover:bg-red-500" ref="zoomIn") +
            button.block.bg-red-200.w-40.h-40.text-white.rounded-b-md(class="hover:bg-red-500" ref="zoomOut") -
        
        //Desktop legend
        template(v-if="!showModal")
            .absolute.bottom-0.left-0.flex.flex-col.p-3.rounded-md.gap-5(:style="'background: rgba(169,169,169, .1);'" class="hidden 1024:block w-full 1024:w-500")
                .flex.items-center.gap-2
                    .w-10.h-10.rounded-full.bg-red-500
                    .block.text-sm города, участницы из которых продолжают борьбу в конкурсе
                .flex.items-center.gap-2
                    .w-10.h-10.rounded-full.bg-gray-400
                    .block.text-sm города, участницы из которых выбыли из конкурса
        //Mobile legend
        .flex.flex-col.p-3.rounded-md.gap-5.mb-15(:style="'background: rgba(169,169,169, .1);'" class="block 1024:hidden w-full")
            .flex.items-center.gap-2
                .w-10.h-10.rounded-full.bg-red-500
                .block.text-xs города, участницы из которых продолжают борьбу в конкурсе
            .flex.items-center.gap-2
                .w-10.h-10.rounded-full.bg-gray-400
                .block.text-xs города, участницы из которых выбыли из конкурса

        //Mobile reset
        template(v-if="activeCityName")
            .text-sm.underline.text-red-500.text-right.mb-5(class="block 1024:hidden" @click="onResetMobile") Показать весь список участниц
        //Mobile participants block
        participants.relative(class="block 1024:hidden" @close="onCloseModal" :city="activeCityName" :hideControls="true")
            template(v-for="(item, index) in filteredParticipantsMobile")
                participant-block-mobile.mb-15(:participant="item" :key="item._id" :id="active_user_id" @value="active_user_id = $event")
</template>

<script>
import Panzoom from '@panzoom/panzoom'
import Map from './base-map'
import Participants from './base-participants'
import ParticipantBlock from './participant-block'
import ParticipantBlockMobile from './participant-block-mobile'
// import { PARTICIPANTS } from '@/configs/text'
//Store
import { ParticipantsNameSpace, ParticipantsActionTypes } from '@/store/participants/types';

export default {
    name: "MapPan",
    components: {
        'app-map': Map,
        'participants': Participants,
        'participant-block': ParticipantBlock,
        'participant-block-mobile': ParticipantBlockMobile, 
    },
    data() {
        return {
            showModal: false,
            resetBubbles: '',
            activeCity: '',
            activeCityName: '',
            participants: [],
            participantsMobile: [],
            filteredParticipants: [],
            filteredParticipantsMobile: [],
            active_user_id: '',
        }
    },
    computed: {
        IMGS() {
            return {
                map: require('../assets/map.svg'),
                uzor : require('../assets/uzor.svg'),
                circle: require('../assets/circle.svg'),
            }
        },
        isMobile() {
            return window.innerWidth < 1024 ? true : false
        },
        // PARTICIPANTS() {
        //     return PARTICIPANTS
        // },
    },
    mounted() {
        this.panzoomInit()
        this.onLoadMobile()
    },
    methods: {
        panzoomInit() {
            const elem = this.$refs['panzoom-el']
            const buttonZoomIn = this.$refs['zoomIn']
            const buttonZoomOut = this.$refs['zoomOut']
            const panzoom = Panzoom(elem, {
                maxScale: 5
            })
            
            panzoom.pan(10, 10)
            panzoom.zoom(1, { animate: true })
            elem.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)
            buttonZoomIn.addEventListener('click', panzoom.zoomIn)
            buttonZoomOut.addEventListener('click', panzoom.zoomOut)
            return panzoom
        },
        onReset() {
            this.panzoomInit().reset()
        },  
        onResetBubbles() {
            this.resetBubbles = ''
        },
        async onCity({alias, name}) {
            if (this.activeCity !== alias) {
                if (this.isMobile) {
                    await this.onLoadMobile()
                    this.filterParticipantsByCityMobile(name)
                } else {
                    await this.onLoad()
                    this.filterParticipantsByCity(name)
                }
                
                const el = document.getElementById('svgmap')
                el.classList.add('absolute', 'left-1/2')
                
                this.showModal = true
                this.activeCity = alias
                this.activeCityName = name
                this.resetBubbles = alias
                
            } else {
                this.onCloseModal()
                this.activeCity = ''
                this.resetBubbles = ''
            }
        },
        filterParticipantsByCity(city) {
            this.filteredParticipants = this.participants.filter(p => {
                return p.city.toLowerCase() === city.toLowerCase()
            })
        },
        filterParticipantsByCityMobile(city) {
            this.filteredParticipantsMobile = this.participantsMobile.filter(p => {
                return p.city.toLowerCase() === city.toLowerCase()
            })
        },
        onCloseModal() {
            this.showModal = false
            const el = document.getElementById('svgmap')
            el.classList.remove('left-1/2')
            this.panzoomInit()
            this.onResetBubbles()
        },
        async onResetMobile() {
            this.panzoomInit()
            this.onResetBubbles()
            this.activeCityName = ''
            this.activeCity = ''
            await this.onLoadMobile()
        },
        async onLoad() {
            try {
                const { data } = await this.$store.dispatch(`${ParticipantsNameSpace}/${ParticipantsActionTypes.GetParticipants}`, { params: { page: 0, per_page: 20 } })
                this.participants = data.data.data
            } catch (e) {
                console.error(e)
            }
        },
        async onLoadMobile() {
            try {
                const { data } = await this.$store.dispatch(`${ParticipantsNameSpace}/${ParticipantsActionTypes.GetParticipants}`, { params: { page: 0, per_page: 20 } })
                this.participantsMobile = data.data.data
                this.filteredParticipantsMobile = data.data.data
            } catch (e) {
                console.error(e)
            }
        },
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>