<template lang="pug">
    div(class="w-1/2 p-2 1024:p-2")
        .flex.flex-col.gap-1.relative
            transition(name='fade' mode='out-in')
                template(v-if="participant.photo")
                    template(v-if="active_user === participant._id")
                        .absolute.h-130.w-130.rounded-full.right-25.bottom-90.bg-cover.bg-center.shadow-md(
                            :style="`z-index: 100000;background-image: url(${baseUrl}/${participant.photo.path})`"
                        )
            .flex.items-start.flex-col
                button.font-bold.text-accent.underline.cursor-pointer.w-auto.whitespace-nowrap(style="font-size: 12px;" @click="onPhoto") {{ participant.firstname }} {{ participant.lastname }}
                template(v-if="(participant.likes || likes) && likes !== 0")
                    .flex.items-center.mt-5
                        img.block.w-15.h-15.mr-5(:src="heartImage")
                        template(v-if="likes")
                            .text-xs(style="font-size: 10px;") {{ likes }}
            transition(name='fade' mode='out-in')
                template(v-if="active_user === participant._id")
                    .absolute.p-3.bg-white.top-25.shadow-md.z-100.rounded(class="w-full 1024:w-300")
                        .absolute.right-5.top-5.bg-gray-200.w-20.h-20.rounded-full.flex.items-center.justify-center(@click="onPhoto")
                            .text-sm.block ×
                        template(v-if="participant.description")
                            .text-sm.font-bold.pt-10 Идея для бизнеса:&nbsp;
                            .text-sm.pt-10(style="font-size: 13px" v-html="participant.description")
                        template(v-else)
                            .text-sm.pt-10(style="font-size: 13px") Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, perspiciatis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, perspiciatis!
            .text-sm(class="block 1024:hidden" style="font-size: 12px") г. {{participant.city}}
            //- template(v-if="!likedId")
            //-     button.rounded-full.w-100.bg-accent.p-2.text-white.text-xs(@click="onLike") голосовать
            //- template(v-else)
            //-     button.rounded-full.w-100.bg-gray-200.p-2.text-white.text-xs.cursor-default голосовать
</template>

<script>
//Store
import { ParticipantsNameSpace, ParticipantsActionTypes, ParticipantsMutationTypes, ParticipantsGetterTypes } from '@/store/participants/types';

export default {
    name: "ParticipantsBlock",
    props: {
        participant: {
            type: Object,
            default: () => ({}),
        },
        id: {
            type: String,
        }
    },
    data() {
        return {
            showPhoto: false,
            likes: '',
            key: 'ehTUiSiwvItg',
            active_user: '',
        }
    },
    computed: {
        baseUrl() {
            return process.env.VUE_APP_BASE_URL
        },
        heartImage() {
            return require('../assets/heart.svg')
        },
        likedId() {
            return this.$store.getters[`${ParticipantsNameSpace}/${ParticipantsGetterTypes.GetLikedId}`]
        },
    },
    async mounted() {
        this.likes = null 
        this.likes = this.participant.likes
        if (localStorage.getItem('watcher'))
            await this.$store.commit(`${ParticipantsNameSpace}/${ParticipantsMutationTypes.SetLikedId}`, { id: this.participant._id })
    },
    methods: {
        formatString(str) {
            if (str.length > 45) {
                return str.substr(0, 45) + '...'
            } else {
                return str
            }
        },
        onPhoto() {
            if (this.active_user !== this.participant._id) {
                this.$emit('value', this.participant._id)
            } else {
                this.$emit('value', null)
            }
        },
        async onLike() {
            const {data} = await this.$store.dispatch(`${ParticipantsNameSpace}/${ParticipantsActionTypes.AddLike}`, { id: this.participant._id })
            this.likes = data.data.likes
            localStorage.setItem('watcher', this.key)
            await this.$store.commit(`${ParticipantsNameSpace}/${ParticipantsMutationTypes.SetLikedId}`, { id: this.participant._id })
        }

    },
    watch: {
        id: {
            handler(val) {
                this.active_user = val
            }
        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>