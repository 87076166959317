<template lang="pug">
    unauthorized-layout
        template
            map-page
</template>

<script>
import UnauthorizedLayout from '@/layouts/unauthorized-layout';
import Map from '@/components/views/map';

export default {
    name: "MapPage",
    metaInfo: {
        title: 'Бизнес-реалити Сверхновая: Правила',
    },
    components: {
        'unauthorized-layout': UnauthorizedLayout,
        'map-page': Map,
    },
    created() {
        const script_el = document.createElement('script');
        const jscode = document.createTextNode("dataLayer.push({ 'event': 'scrn', 'screenName': '/details', 'buttonLocation': null, 'actionGroup': 'non_interactions', 'touchPoint': 'web'});")
        script_el.appendChild(jscode);
        document.head.appendChild(script_el)
    }    
}
</script>